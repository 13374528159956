/* ========== MAIN DIV THAT HOLDS THE ENTIRE MENU ========== */
.flex_menu_container{
	width:100%;
	height: 80px;
	margin:0 auto 0;
	background:#444;
	border-bottom:solid 1px #444;
	FONT-WEIGHT: 700;
	}
.menu {
    height: 40px;
    background: #000000;
}
/* ========== DIV THAT HOLDS MAIN LINKS ========== */
.menu ul{
	margin:0;
	padding:0;
	position:relative;
	float:left;
	list-style:none;
	width: 100%;
	}
/* ========== MAIN LINKS ========== */
.menu ul li{
	float:left;
	}
.menu ul li a{
    display: block;
    height: 39px;
    padding: 9px 10px 13px 10px;
    font-size: 14px;
    text-transform: uppercase;
    color:#FFF;
    text-decoration:none;
    border-right:solid 1px #1E1E1E;
 }

/* ========== MAIN LINKS ON MOUSEOVER ========== */
.menu > ul > li.current > a{
	color:#ed520f;
	background:#000;
	}
	.menu > ul > li.current:hover > a, .menu > ul > li:hover > a{
	color:#ed520f;
	background:#444;
	}
/* ========== MAIN LINKS ON MOUSE CLICK ========== */	
.menu > ul > li > a:active, .menu > ul > li.current > a:active{
	color:#ed520f;
	background:#000;
	}
/* ========== SUBMENU ========== */	
.menu ul ul{
	width:100%;
	height:33px;
	position:absolute;
	left:0;
	visibility:hidden;
	opacity:0;
	}

.menu ul li a.active   {
		color: #ed520f 
		}  
.animation-1 ul ul, .no-js ul ul{
	-webkit-transition:all 170ms linear 10ms;
	-moz-transition:all 170ms linear 10ms;
	-ms-transition:all 170ms linear 10ms;
	-o-transition:all 170ms linear 10ms;
	transition:all 170ms linear 10ms;
	}
.animation-2 ul ul, .animation-3 ul ul, .animation-4 ul ul{
	-webkit-transition:all 250ms ease-out 10ms;
	-moz-transition:all 250ms ease-out 10ms;
	-ms-transition:all 250ms ease-out 10ms;
	-o-transition:all 250ms ease-out 10ms;
	transition:all 250ms ease-out 10ms;
	}
.animation-2 ul ul{
	margin-top:31px;
	}	
.animation-3 ul ul{
	margin-left:38px;
	}
.animation-4 ul ul{
	margin-left:-42px;
	}	
.menu ul li.current ul{
	visibility:visible;
	opacity:1;
	display: block;
	}
/* ===== JavaScript Is Disabled ===== */	
.no-js ul:hover li.current ul{
	visibility:hidden;
	opacity:0;
	}
.no-js ul li.current ul, .no-js ul li.current:hover ul, .no-js ul li:hover ul{
	display:block;
	visibility:visible;
	opacity:1;
	}
/* ========== LINKS IN SUBMENU ========== */	
.menu ul ul li.current{
	background:#1d1d1d;
	}
	.menu ul ul li:hover{
		background:#3e3e3d;
		color:#fff;
		}

.menu ul ul li a{
	display:inline;
	margin:5px;
	padding:10px;
	color:#fff;
	line-height: 40px;
	border-right:none;
	-webkit-transition-duration:140ms;
	-moz-transition-duration:140ms;
	-ms-transition-duration:140ms;
	-o-transition-duration:140ms;
	transition-duration:140ms;
	}
/* ========== LINKS ON MOUSEOVER IN SUBMENU ========== */
.menu ul ul li.current a, .menu ul ul li a:hover{
	color:#ed520f;
	text-decoration: underline;
    text-underline-offset: 5px;
	}
/* ========== SOCIAL ICONS ========== */
.menu .social{
	display:block;
	margin:0 8px 0 0;
	padding:0;
	list-style:none;
	float:right;
	}
.menu .social li{
	float:left;
	}
.menu .social li a{
	display:block;
	height:33px;
	padding:0 7px;
	font-size:16px;
	color:#ed520f;
	line-height:28px;
	border-right:none;
	-webkit-transition:color 170ms linear 20ms, background 170ms linear 20ms;
	-moz-transition:color 170ms linear 20ms, background 170ms linear 20ms;
	-ms-transition:color 170ms linear 20ms, background 170ms linear 20ms;
	-o-transition:color 170ms linear 20ms, background 170ms linear 20ms;
	transition:color 170ms linear 20ms, background 170ms linear 20ms;
	}
.menu .social li:hover a{
	color:#FFF;
	background:#282828;
	}
.menu .social li a:active{
	background:#1F1F1F;
	}

ul.dashboard.dropdown-menu {
	background: none;
	border: none;	
}

@media screen and (max-width: 1120px) {
    .flex_menu_container {
        height: 101px;
    }

    .menu ul ul {
        height: 66px;
    }

	.menu ul ul li a {
		padding: 5px 10px;
		line-height: 31px;
	}
}



