@media (max-width: 992px) {
    .dashboard-container {
        margin: 0;
    }
    .navbar {
        color: #fff;
        float: none;
        min-width: auto;
        background-image: none;
        background-color: #444444;
        border-color: #444444;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
    }

    .flex-benefits {
        width: 40%;
        height: auto;
    }

    #navbar > ul > li {
        font-size: 14px !important;
        min-height: 22px !important;
        border-bottom: 0 solid #777 !important;
        border-right: 0 solid #777 !important;
    }
    
    #navbar ul.dropdown-menu li a {
        text-transform: uppercase;
    }

    #navbar li .dropdown-sign i {
        font-size: 13px !important;
        visibility: visible !important;
        color: #fff !important;
    }

    #navbar li .dropdown-sign {
        border-left: none !important;
        padding-top: 0 !important;
        font-size: 14px !important;
        padding-left: 1px;
        color: #fff !important;
        height: 30px !important;
        color: #fff !important;
        padding-top: 8px !important;
        top: 0 !important;
        right: 0 !important;
    }

        #navbar li .dropdown-sign i {

            opacity: 1;
        }

    .navbar-default .navbar-brand {
        padding: 18px 17px;
        color: #fff;
        cursor: default;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
        height: auto;
    }
    #navbar li .dropdown-sign {
        margin-right: 0;
        width: 20px;
        height: 45px;
        border-left: 1px solid #4C4C4C;
        padding-top: 17px;
        padding-left: 1px;
        z-index: 100;
        top: 0;
        right: 0;
    }
    #navbar li .dropdown-sign:hover {
        border-left: 1px solid #aaa;
    }
    #navbar li a .dropdown-sign i {
        margin-top: 10px;
        margin-left: 10px;
        color: #fff;
    }
    .navbar-default .navbar-brand:hover {
        color: #fff;
    }
    .navbar-default .navbar-toggle {
        position: absolute;
        right: 0;
    }
    .navbar-default .navbar-toggle:focus,
    .navbar-default .navbar-toggle:hover {
        background-color: transparent;
    }
    .navbar-default .navbar-toggle {
        border-color: transparent;
    }
    .navbar-default .navbar-toggle .icon-bar {
        background-color: #fff;
    }
    .navbar-collapse {
        border: none;
    }
    .navbar-nav {
        margin: 0;
    }
    #navbar>ul>li {
        min-height: inherit;
        width: auto;
        border: none;
        background-image: none;
        border-top: 1px solid #4C4C4C;
        background: #444444;
    }
        #navbar > ul > li > a {
            text-align: left;
            color: #fff;
            padding: 15px;
            margin: 5px;
        }
    #navbar ul li i {
        display: inline;
        padding: 0 10px;
    }
    #navbar ul.dropdown-menu {
        margin: 0;
    }
    #navbar ul.dropdown-menu li a {
        background: #555;
        height: 48px;
        padding: 10px 10px 10px 60px;
    }
    #navbar ul.dropdown-menu li a:hover {
        background: #575757;
    }
    #navbar .open li {
        height: 47px;
        border-top: 1px solid #4C4C4C;
    }
}

@media (max-width: 767px) {

    #scrollUp {
        margin: 2px;
        display: none !important;
    }
    header .logo {
        width: 100px;
    }
    header .logo img {
        max-width: 120px;
    }
    .left-sidebar {
        margin-right: 0px;
    }
    .widget .widget-header span.tools {
        display: none;
    }
    .widget .widget-header .tools {
        display: none;
    }
    .right-sidebar {
        display: none;
    }
    .icons-container li {
        width: 100%;
    }
    .metro-nav .metro-nav-block {
        width: 49%;
    }
    .metro-nav .metro-nav-block:hover {
        outline: none;
        opacity: 0.8;
    }
    .metro-nav .metro-nav-block.double {
        width: 99%;
    }
    /*hide the text when on a phone*/
        .steps-indicator li a {
        visibility: hidden;
    }
    .steps-indicator li a:hover {
        color: transparent;
    }
    .bk-date-picker .btn {
        margin: 0px;
    }
    .grid-item,
    .grid-sizer {
        width: 100%;
    }
    .menuText {
        position: absolute;
        bottom: 17px;
        left: 50px;
    }
    .btn-toolbar {
        margin-left: 0;
        margin-right: 0;
    }
    .btn-toolbar input,
    .btn-toolbar a,
    .btn-toolbar button {
        margin-top: 5px;
        margin-left: 0;
        margin-right: 0;
    }
    .btn-toolbar>.btn {
        margin-left: 0;
        margin-right: 0;
    }
    .connect-image {
        -ms-background-size: 80%;
        background-size: 80%;
    }
    .ng-aside .modal-dialog {
        width: 66%;
    }
    .send-feedback {
        bottom: 0;
        right: 0;
        position: inherit;
        border-radius: 4px;
        border: none;
        margin-bottom: 10px;
    }
    /*User Profile*/
    ul#mini-nav .list-box:nth-last-child(1) .dropdown-menu {
        right: 0;
    }
    ul#mini-nav .list-box:nth-last-child(1) .dropdown-menu:before {
        right: 14px;
    }
    /*Task Planner*/
    ul#mini-nav .list-box:nth-last-child(2) .dropdown-menu {
        right: -50px;
    }
    ul#mini-nav .list-box:nth-last-child(2) .dropdown-menu:before {
        right: 66px;
    }
    /*Task Planner*/
    ul#mini-nav .list-box:nth-last-child(3) .dropdown-menu {
        right: -104px;
    }
    ul#mini-nav .list-box:nth-last-child(3) .dropdown-menu:before {
        right: 120px;
    }
    /**/
    ul#mini-nav .list-box:nth-last-child(4) .dropdown-menu {
        right: -158px;
    }
    ul#mini-nav .list-box:nth-last-child(4) .dropdown-menu:before {
        right: 171px;
    }
    .account-details .contact-information {
        font-size: 3.5vw;
    }
    div.calendar-title h3 {
        font-size: 20px;
    }
    .brooksonCalendar .month {
        height: 100px;
    }
    .brookson-calendar-month .event-title,
    .brooksonCalendar .event-title {
        display: block;
        white-space: nowrap;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .brookson-calendar-month .day {
        min-height: 90px;
    }
    .ir35 .panel-white .panel-body .buttons {
        float: none;
    }
    .ir35 .panel-white .panel-body .buttons a {
        width: 100%;
        margin-top: 10px;
    }
    .ir35 .well.accept-guidelines {
        width: 100%;
    }
    .alternative .modal-header h3 {
        font-size: 18px;
        margin-top: 8px;
    }
    .modal-content {
        margin: 15px;
    }
    .form-horizontal .valid-vat-container, .form-horizontal .valid-vat-container .control-label {
        text-align: left;
    }
    .form-horizontal .valid-vat-container input[type=checkbox] {
        margin-left: 40px;
    }
    .no-padding-right-xs {
        padding-right: 0;
    }
    .no-padding-left-xs {
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    .navbar-brand {
        display: none;
    }
    #account-container {
        width: 750px;
    }
    .container {
        width: 100%;
        padding: 0 15px;
    }
    .container .jumbotron {
        padding: 20px;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
    }
    .form-horizontal .control-label {
        text-align: left;
    }
    .grid-item,
    .grid-sizer {
        width: 50%;
    }
    .btn-toolbar input,
    .btn-toolbar a,
    .btn-toolbar button {
        margin-left: 5px;
    }
    .btn-toolbar>.btn {
        margin-left: 5px;
    }
    #expensesForm .btn-toolbar>.btn {
        margin-left: 0px;
    }
    .document-storage-toolbar .fa {
        font-size: 18px;
        vertical-align: middle;
    }
    .document-storage-toolbar .btn {
        padding: 5px;
    }


    .sdc-modal .modal-body .btn-toolbar button {
        width: inherit;
    }
}

@media (min-width: 992px) {
    .container {
        width: 100%;
        padding: 0 15px;
    }
    
    #navbar ul li.open span.dropdown-sign>i {
        color: rgba(255, 255, 255, 1);
    }

    #account-container {
        width: 970px;
    }

    .btn-toolbar input,
    .btn-toolbar a,
    .btn-toolbar button {
        margin-left: 5px;
    }

    .btn-toolbar>.btn {
        margin-left: 5px;
    }

    .headline-brooksonone {
        padding-right: 10px;
    }

    .welcomescreen-thumbnailgrid {
        margin-left: 26px;
    }
}

@media (max-width: 992px) {
    #map map {
        padding: 15px 0 0 0;
    }
    .panel-control.spaced-medium a {
        margin-left: 10px;
    }
    .btn-group.btn-group-justified button {
        font-size: 1.3vw;
    }

    .headline-brooksonone {
        top: 43px;
        padding-right: 10px;
    }

    .welcomescreen-header {
        margin: 40px 0 20px;
    }

    .headline-bar {
        line-height: 10pt;
    }

    .overlay .closebtn {
        font-size: 45px;
    }

    .welcomescreen-aside {
        padding: 2em;
        font-size: 16px;
        width: 100%;
        max-width: 992px;
        margin-left: auto;
        margin-right: auto;
    }
    
}

@media (min-width: 1200px) {
    .container {
        width: 100%;
        padding: 0 15px;
    }
    #account-container {
        width: 1170px;
    }
    .grid-item,
    .grid-sizer {
        width: 33.33%;
    }
    
    .welcomescreen-bookmessage
    {
        margin-top: 20px;
    }

    .umbrella-panel {
        border-bottom: solid #9e9e9e 4px;
        padding: 20px 20px 0 10px;
    }
}

@media (max-width: 1500px) {
    .mini-widget .mini-widget-body .meta.raftile {
        margin-left: 15px !important;
    }
}


@media (min-width: 1400px) {
    .grid-item,
    .grid-sizer {
        width: 25%;
    }
}

@media screen and (max-width: 768px) {
    .invoice-btn-toolbar button {
        margin-bottom: 5px;
    }
    .crop-receipt {
        visibility: hidden;
    }
    .bk-nav-justified {
        display: flex;
        justify-content: space-between;
    }
    .bk-nav-justified::before {
        display: initial;
        content: initial;
    }
    .bk-nav-justified::after {
        display: initial;
        content: initial;
    }
    #expenses-tabs #tabs>ul>li>a {
        padding: 10px 7px;
    }
    .overlay .checkboxclose {
        right: 50px;
        top: 0px;
    }
    .overlay .closebtn {
        right: 0px;
        top: 5px;
    }
}

@media (max-width: 1200px) {
    .timeline {
        padding: 15px 10px;
    }
    .timeline:after {
        left: 28px;
    }
    .timeline .timeline-row {
        padding-left: 0;
        margin-bottom: 16px;
    }
    .timeline .timeline-row .timeline-time {
        position: relative;
        right: auto;
        top: 0;
        text-align: left;
        margin: 0 0 6px 56px;
    }
    .timeline .timeline-row .timeline-time strong {
        display: inline-block;
        margin-right: 10px;
    }
    .timeline .timeline-row .timeline-icon {
        top: 52px;
        left: -2px;
        margin-left: 0;
    }
    .timeline .timeline-row .timeline-content {
        margin-left: 56px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        position: relative;
    }
    .timeline .timeline-row .timeline-content:after {
        right: auto !important;
        left: -20px !important;
        top: 32px;
    }
    .timeline .timeline-row:nth-child(odd) {
        padding-right: 0;
    }
    .timeline .timeline-row:nth-child(odd) .timeline-time {
        position: relative;
        right: auto;
        left: auto;
        top: 0;
        text-align: left;
        margin: 0 0 6px 56px;
    }
    .timeline .timeline-row:nth-child(odd) .timeline-content {
        margin-right: 0;
        margin-left: 55px;
    }
    .timeline.animated .timeline-row:nth-child(odd) .timeline-content {
        left: 20px;
    }
    .timeline.animated .timeline-row.active:nth-child(odd) .timeline-content {
        left: 0;
    }
    .crop-receipt {
        visibility: hidden;
    }
}

@media (min-width: 1200px) {
    .bk-invoicing .container-small {
        padding: 15px 15px 15px 15px;
        width: 50%;
    }
    .overlay-content h1 {
        font-size: 3em;
    }    
}

@media only screen and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .document-storage-back-button {
        visibility: visible;
    }
}

1280

@media (max-width: 375px) {
    .dashboard-container {
        margin: 0;
    }
    .headline-bar {
        margin: 0;
    }
    .headline-brooksonone
    {
         padding-right: 0;
    }
    .dashboard-wrapper-lg {
        padding: 0px;
        padding-top: 15px;
    }
    .add-margin-right-3 {
        margin-right: 12px;
    }
    .bk-nav-justified>li>a {
        padding: 5px 5px;
        font-size: 11px;
    }
    .hidden-xxs {
        display: none !important;
    }
    .col-xxs-1,
    .col-xxs-2,
    .col-xxs-3,
    .col-xxs-4,
    .col-xxs-5,
    .col-xxs-6,
    .col-xxs-7,
    .col-xxs-8,
    .col-xxs-9,
    .col-xxs-10,
    .col-xxs-11,
    .col-xxs-12 {
        float: left;
    }
    .col-xxs-12 {
        width: 100%;
    }
    .col-xxs-11 {
        width: 91.66666667%;
    }
    .col-xxs-10 {
        width: 83.33333333%;
    }
    .col-xxs-9 {
        width: 75%;
    }
    .col-xxs-8 {
        width: 66.66666667%;
    }
    .col-xxs-7 {
        width: 58.33333333%;
    }
    .col-xxs-6 {
        width: 50%;
    }
    .col-xxs-5 {
        width: 41.66666667%;
    }
    .col-xxs-4 {
        width: 33.33333333%;
    }
    .col-xxs-3 {
        width: 25%;
    }
    .col-xxs-2 {
        width: 16.66666667%;
    }
    .col-xxs-1 {
        width: 8.33333333%;
    }
    .col-xxs-pull-12 {
        right: 100%;
    }
    .col-xxs-pull-11 {
        right: 91.66666667%;
    }
    .col-xxs-pull-10 {
        right: 83.33333333%;
    }
    .col-xxs-pull-9 {
        right: 75%;
    }
    .col-xxs-pull-8 {
        right: 66.66666667%;
    }
    .col-xxs-pull-7 {
        right: 58.33333333%;
    }
    .col-xxs-pull-6 {
        right: 50%;
    }
    .col-xxs-pull-5 {
        right: 41.66666667%;
    }
    .col-xxs-pull-4 {
        right: 33.33333333%;
    }
    .col-xxs-pull-3 {
        right: 25%;
    }
    .col-xxs-pull-2 {
        right: 16.66666667%;
    }
    .col-xxs-pull-1 {
        right: 8.33333333%;
    }
    .col-xxs-pull-0 {
        right: auto;
    }
    .col-xxs-push-12 {
        left: 100%;
    }
    .col-xxs-push-11 {
        left: 91.66666667%;
    }
    .col-xxs-push-10 {
        left: 83.33333333%;
    }
    .col-xxs-push-9 {
        left: 75%;
    }
    .col-xxs-push-8 {
        left: 66.66666667%;
    }
    .col-xxs-push-7 {
        left: 58.33333333%;
    }
    .col-xxs-push-6 {
        left: 50%;
    }
    .col-xxs-push-5 {
        left: 41.66666667%;
    }
    .col-xxs-push-4 {
        left: 33.33333333%;
    }
    .col-xxs-push-3 {
        left: 25%;
    }
    .col-xxs-push-2 {
        left: 16.66666667%;
    }
    .col-xxs-push-1 {
        left: 8.33333333%;
    }
    .col-xxs-push-0 {
        left: auto;
    }
    .col-xxs-offset-12 {
        margin-left: 100%;
    }
    .col-xxs-offset-11 {
        margin-left: 91.66666667%;
    }
    .col-xxs-offset-10 {
        margin-left: 83.33333333%;
    }
    .col-xxs-offset-9 {
        margin-left: 75%;
    }
    .col-xxs-offset-8 {
        margin-left: 66.66666667%;
    }
    .col-xxs-offset-7 {
        margin-left: 58.33333333%;
    }
    .col-xxs-offset-6 {
        margin-left: 50%;
    }
    .col-xxs-offset-5 {
        margin-left: 41.66666667%;
    }
    .col-xxs-offset-4 {
        margin-left: 33.33333333%;
    }
    .col-xxs-offset-3 {
        margin-left: 25%;
    }
    .col-xxs-offset-2 {
        margin-left: 16.66666667%;
    }
    .col-xxs-offset-1 {
        margin-left: 8.33333333%;
    }
    .col-xxs-offset-0 {
        margin-left: 0;
    }
    .tree-grid .level-1 .indented,
    .tree-grid .level-2 .indented,
    .tree-grid .level-3 .indented,
    .tree-grid .level-4 .indented,
    .tree-grid .level-5 .indented {
        left: 0;
    }
    .tree-grid .level-1 td:nth-child(2) div span {
        left: 0;
    }
    .tree-grid .level-2 td:nth-child(2) div span {
        left: 15px;
    }
    .tree-grid .level-3 td:nth-child(2) div span {
        left: 30px;
    }
    .tree-grid .level-4 td:nth-child(2) div span {
        left: 45px;
    }
    .tree-grid .level-5 td:nth-child(2) div span {
        left: 60px;
    }
    .tree-grid .level-1 td:nth-child(2) div span,
    .tree-grid .level-2 td:nth-child(2) div span,
    .tree-grid .level-3 td:nth-child(2) div span,
    .tree-grid .level-4 td:nth-child(2) div span,
    .tree-grid .level-5 td:nth-child(2) div span {
        width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }
    .bk-invoicing {
        padding: 0 5px 0 5px;
    }
    .nav>li>a {
        padding: 10px 10px;
    }
    .nav {
        margin: 0 auto;
    }
    .xs-add-top-bottom-margin {
        margin: 5px 0;
    }
    .xs-mm-smaller-panel {
        padding: 5px;
    }
    .xs-mm-smaller-panel div {
        padding: 0;
    }
    .xs-mm-smaller-panel span {
        padding: 0 5px;
        font-size: 10px;
    }
    .xs-mm-smaller-panel span dt {
        font-size: 11px;
    }
}

@media (min-width: 468px) {
    .assignment-preview-icon {
        opacity: 0.5;
        font-size: 10em;
    }
}

@media (max-width: 468px) {
    .assignment-preview-icon {
        opacity: 0.5;
        font-size: 4em;
    }
}

@media (min-width: 992px) {
    brookson-manager-money-weekly-account-summary-breakdown .widget-body,
    brookson-manager-money-weekly-account-summary-selected .widget-body {
        max-height: 350px;
        overflow-y: auto;
    }
    .add-another-expense {
        position: absolute;
        right: 33%;
        bottom: -39px;
    }
    .overlay-content h1 {
        font-size: 2em;
    }    
}

@media (max-width: 1600px) and (min-width: 1200px) {
    .col-expense-partial.col-lg-5, .col-expense-partial.col-lg-7 {
        width: 100%;
    }
}

@media screen and (max-height: 450px) {
    .overlay a {font-size: 20px}
    .overlay .closebtn {
      font-size: 40px;
      top: 15px;
      right: 35px;
    }

    .overlay-content h1 {
        font-size: 3em;
    }
  }

  @media (min-width: 992px) and (max-width: 1280px) {
    .hidden-mds {
        display: none !important;
    }
    .col-mds-1,
    .col-mds-2,
    .col-mds-3,
    .col-mds-4,
    .col-mds-5,
    .col-mds-6,
    .col-mds-7,
    .col-mds-8,
    .col-mds-9,
    .col-mds-10,
    .col-mds-11,
    .col-mds-12 {
        float: left;
    }
    .col-mds-12 {
        width: 100%;
    }
    .col-mds-11 {
        width: 91.66666667%;
    }
    .col-mds-10 {
        width: 83.33333333%;
    }
    .col-mds-9 {
        width: 75%;
    }
    .col-mds-8 {
        width: 66.66666667%;
    }
    .col-mds-7 {
        width: 58.33333333%;
    }
    .col-mds-6 {
        width: 50%;
    }
    .col-mds-5 {
        width: 41.66666667%;
    }
    .col-mds-4 {
        width: 33.33333333%;
    }
    .col-mds-3 {
        width: 25%;
    }
    .col-mds-2 {
        width: 16.66666667%;
    }
    .col-mds-1 {
        width: 8.33333333%;
    }
    .col-mds-pull-12 {
        right: 100%;
    }
    .col-mds-pull-11 {
        right: 91.66666667%;
    }
    .col-mds-pull-10 {
        right: 83.33333333%;
    }
    .col-mds-pull-9 {
        right: 75%;
    }
    .col-mds-pull-8 {
        right: 66.66666667%;
    }
    .col-mds-pull-7 {
        right: 58.33333333%;
    }
    .col-mds-pull-6 {
        right: 50%;
    }
    .col-mds-pull-5 {
        right: 41.66666667%;
    }
    .col-mds-pull-4 {
        right: 33.33333333%;
    }
    .col-mds-pull-3 {
        right: 25%;
    }
    .col-mds-pull-2 {
        right: 16.66666667%;
    }
    .col-mds-pull-1 {
        right: 8.33333333%;
    }
    .col-mds-pull-0 {
        right: auto;
    }
    .col-mds-push-12 {
        left: 100%;
    }
    .col-mds-push-11 {
        left: 91.66666667%;
    }
    .col-mds-push-10 {
        left: 83.33333333%;
    }
    .col-mds-push-9 {
        left: 75%;
    }
    .col-mds-push-8 {
        left: 66.66666667%;
    }
    .col-mds-push-7 {
        left: 58.33333333%;
    }
    .col-mds-push-6 {
        left: 50%;
    }
    .col-mds-push-5 {
        left: 41.66666667%;
    }
    .col-mds-push-4 {
        left: 33.33333333%;
    }
    .col-mds-push-3 {
        left: 25%;
    }
    .col-mds-push-2 {
        left: 16.66666667%;
    }
    .col-mds-push-1 {
        left: 8.33333333%;
    }
    .col-mds-push-0 {
        left: auto;
    }
    .col-mds-offset-12 {
        margin-left: 100%;
    }
    .col-mds-offset-11 {
        margin-left: 91.66666667%;
    }
    .col-mds-offset-10 {
        margin-left: 83.33333333%;
    }
    .col-mds-offset-9 {
        margin-left: 75%;
    }
    .col-mds-offset-8 {
        margin-left: 66.66666667%;
    }
    .col-mds-offset-7 {
        margin-left: 58.33333333%;
    }
    .col-mds-offset-6 {
        margin-left: 50%;
    }
    .col-mds-offset-5 {
        margin-left: 41.66666667%;
    }
    .col-mds-offset-4 {
        margin-left: 33.33333333%;
    }
    .col-mds-offset-3 {
        margin-left: 25%;
    }
    .col-mds-offset-2 {
        margin-left: 16.66666667%;
    }
    .col-mds-offset-1 {
        margin-left: 8.33333333%;
    }
    .col-mds-offset-0 {
        margin-left: 0;
    }
}